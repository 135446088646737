import React, { ChangeEvent, useState } from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { AccountApi, IPasswordResetDto, ISendPasswordResetCodeDto } from '../../apis/account-api';
import logo from '../../assets/images/logo.png';

import 'react-toastify/dist/ReactToastify.css';
import "./forget-password.scss";
import usePageTitle from '../../hooks/usePageTitle';
import useGaTracker from '../../hooks/useGaTracker';
import { useSearchParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

export default function PasswordReset() {
    useGaTracker();
    usePageTitle("Password Reset");

    const recaptchaRef: any = React.createRef();
    
    const [searchParams, setSearchParams] = useSearchParams();    
    
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [buttonClass, setButtonClass] = useState('');

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (email.indexOf('@') === -1) {
            setErrorMessage("Please enter a valid email address!");
            return;
        }
        
        try {
            setLoading(true);
        
            const token = await recaptchaRef.current.executeAsync();

            let dto: ISendPasswordResetCodeDto = {
                email: email,
                recaptcha: token,
            };

            let r = await AccountApi.sendPasswordResetCodeDto(dto);
            if (r){                
                toast.success('Password reset link send!', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });                    
            }
            else{
                setErrorMessage("Failed to send password reset link!");
            }
        } catch (error) {
            console.log(error);
            setErrorMessage("An error occurred while send request. Please try again later.");
        }

        setLoading(false);
    };

    return (
        <div className="page">
            <div className="container">
                <div className="forget-password">
                    <img src={logo} alt="Logo" />
                    <h1>HELL Export Web Store - Forget password</h1>
                    <div className="panel">
                        <form onSubmit={handleSubmit}>                           
                            <div className="form-group">
                                <label>Email</label>
                                <input type="type" value={email} onChange={handleEmailChange} />
                            </div>      
                            <ReCAPTCHA 
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} 
                                size="invisible" />
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <div className="button-container">
                                <button disabled={loading || disableButton} className={buttonClass}>
                                    <div className={loading ? 'loader' : ''}>{loading ? '' : 'Reset password'}</div>
                                </button>
                                {loading && <div className="progress-bar" />}
                            </div>
                            <a className="login-link" href='/login'>Login</a>
                        </form>
                    </div>
                </div>
                <ToastContainer transition={Slide} />
            </div>
        </div>
    )
}