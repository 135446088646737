import './product-item.scss'
import 'photoswipe/dist/photoswipe.css'

import React, { FC, ReactElement, useEffect, useState, useRef } from 'react';

import placeholder from '../../assets/images/placeholder.jpg';
import { ICartItem } from '../cart/cart';
import { toast } from 'react-toastify';
import { formatNumber } from '../../services/app-messages-service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Gallery, Item } from 'react-photoswipe-gallery'
import FsLightbox from 'fslightbox-react';

export interface IProductItem {
  id: string;
  sku: string;
  name: string;
  brandName: string;
  image: string;
  galleryImages: string[];
  price: number;
  priceWithDiscount: number;
  preOrdered: number;
  shipped: number;
  remaining: number;
  inStock: number;
}

const ProductItem: FC<IProductItem> = ({ id, sku, name, brandName, image, galleryImages, price, priceWithDiscount, preOrdered, shipped, remaining, inStock }): ReactElement => {
  const [quantity, setQuantity] = useState<number>(1);

  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  const [resetInputs, setResetInputs] = useState<boolean>(false);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]') as ICartItem[];
      setCartItems(updatedCartItems);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const triggerStorageChange = () => {
    const storageChangeEvent = new Event('storage');
    window.dispatchEvent(storageChangeEvent);

  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity;

    if (resetInputs) {
      newQuantity = 1;
      setResetInputs(false);
      setQuantity(newQuantity);

      // Ha resetInputs true, akkor fókuszálj ki az inputról
      if (inputRef.current) {
        inputRef.current.blur();
      }
    } else {
      newQuantity = parseInt(event.target.value, 10);
      setQuantity(newQuantity);

      // Hozzáadott fókuszálás, ha az inputRef.current értéke igaz
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleAddToCart = () => {
    const selectedItem = { id, sku, name, image, priceWithDiscount, quantity } as ICartItem;
    let storedCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]') as ICartItem[];

    const existingItemIndex = storedCartItems.findIndex((item) => item.id === selectedItem.id);

    if (existingItemIndex !== -1) {
      storedCartItems[existingItemIndex].quantity += quantity;
    } else {
      selectedItem.quantity = quantity; // Frissített quantity-t beállítjuk az új elemhez
      storedCartItems.push(selectedItem);
    }

    localStorage.setItem('cartItems', JSON.stringify(storedCartItems));
    setCartItems(storedCartItems);

    triggerStorageChange();

    // setResetInputs(true);
    setQuantity(1);
    toast.success('The product has been added to the cart!', { position: toast.POSITION.BOTTOM_RIGHT });
  };

  // To open the lightbox change the value of the "toggler" prop.
	const [lightboxToggler, setLightboxToggler] = useState(false);
  let images: string[] = [];
  if (image) images.push(image);
  for(let i = 0; i < galleryImages.length; i++){
    if (galleryImages[i]) images.push(galleryImages[i]);
  }

  return (
    <div key={id} className='product'>
      <div className='row'>
        <div className='col-40'>
          <div className={`image ${image ? '' : 'placeholder'}`} onClick={() => setLightboxToggler(!lightboxToggler)}>
            <LazyLoadImage src={image ? image : placeholder} alt={name} />
          </div>
          {image &&
          <FsLightbox
            toggler={lightboxToggler}
            type="image"
            sources={images} />
          }
        </div>
        <div className='col-60'>
          <div className='title'>{name}</div>
          <div className='info'>
            <div className='sku'><span>SKU: </span>{sku}</div>
            <div className='brand'><span>Brand: </span>{brandName}</div>
          </div>
          <div className='stock'>
            <div className='title'>Stock</div>
            <div className='pre-ordered'><span>Pre-ordered: </span>{formatNumber(preOrdered, 0)}</div>
            <div className='shipped'><span>Shipped: </span>{formatNumber(shipped, 0)}</div>
            <div className='remaining'><span>Remaining: </span>{formatNumber(remaining, 0)}</div>
            <div className='in-stock'><span>In stock: </span>{formatNumber(inStock, 0)}</div>
          </div>
          <div className='add-to-cart-container'>
            {price !== priceWithDiscount && <div className='price-original'>{formatNumber(price)} €</div>}
            <div className='price'>{formatNumber(priceWithDiscount)} €</div>
            <div className='responsive-button'>
              <input
                type='number'
                min='1'
                value={resetInputs ? 1 : quantity}
                onChange={handleQuantityChange}
              />
              <button onMouseDown={handleAddToCart}>Add to cart</button>
            </div>
          </div>
          {/* <ToastContainer transition={Slide} /> */}
        </div>
      </div>
    </div>
  )
};

export default ProductItem;