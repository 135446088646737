import './app.css';

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from './components/layout/layout';
import Products from './pages/products/products';
import Orders from './pages/orders/orders';
import NotFound from './pages/not-found/not-found';
import Login from './pages/login/login';
import NoLayout from './components/no-layout/no-layout';
import NoService from './pages/no-service/no-service';
import Order from './pages/order/order';
import ChangePassword from './pages/change-password/change-password';
import Checkout from './pages/checkout/checkout';
import PasswordReset from './pages/password-reset/password-reset';
import ForgetPassword from './pages/forget-password/forget-password';

function App() {
  const userString = localStorage.getItem('user');
  if (userString !== null && userString !== undefined && userString !== "undefined") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="/products" />} />
            <Route path="login" element={<Navigate to="/products" />} />
            <Route path="products" element={<Products />} />
            <Route path="orders" element={<Orders />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="/orders/:id" element={<Order />}/>
            <Route path="/checkout" element={<Checkout />} />
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route path="/no-service" element={<NoService />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<NoLayout />} >
            <Route index element={<Navigate to="/login" />} />
            <Route path='/login' element={<Login />} />
            <Route path='/password-reset' element={<PasswordReset/>} />
            <Route path='/forget-password' element={<ForgetPassword/>} />
            <Route path="/no-service" element={<NoService />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
