import React, { ChangeEvent, useState } from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { AccountApi, IPasswordResetDto } from '../../apis/account-api';
import logo from '../../assets/images/logo.png';

import 'react-toastify/dist/ReactToastify.css';
import "./password-reset.scss";
import usePageTitle from '../../hooks/usePageTitle';
import useGaTracker from '../../hooks/useGaTracker';
import { useSearchParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

export default function PasswordReset() {
    useGaTracker();
    usePageTitle("Password Reset");

    const recaptchaRef: any = React.createRef();

    const [searchParams, setSearchParams] = useSearchParams();    
    
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [buttonClass, setButtonClass] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMessage("New password and confirm password don't match");
            return;
        }
        if (!isValidPassword(newPassword)) {
            setErrorMessage("Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character and must be at least 8 characters");
            return;
        }
        
        try {
            setLoading(true);

            const token = await recaptchaRef.current.executeAsync();
            let dto: IPasswordResetDto = {
                email: searchParams.get("user") ?? "",
                token: searchParams.get("token") ?? "",
                password: newPassword,
                recaptcha: token,
            };

            let r = await AccountApi.passwordReset(dto);
            if (r){                
                toast.success('Password change successfull!', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
    
                setNewPassword('');
                setConfirmPassword('');  
                setErrorMessage('');          
            }
            else{
                setErrorMessage("Failed to change password!");
            }
        } catch (error) {
            console.error("Error while changing password:", error);
            setErrorMessage("An error occurred while changing password. Please try again later.");
        }

        setLoading(false);
    };

    const isValidPassword = (password: string) => {
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharacterRegex = /[!@#$%^&*()-_=+[{}\\]|;:',<.>?]/;
    
        const isLowercase = lowercaseRegex.test(password);
        const isUppercase = uppercaseRegex.test(password);
        const isNumber = numberRegex.test(password);
        const isSpecialCharacter = specialCharacterRegex.test(password);
        const isLengthValid = password.length >= 8;
    
        if (!isLengthValid) {
            setErrorMessage('Password must be at least 8 characters long');
            return false;
        }
    
        return isLowercase && isUppercase && isNumber && isSpecialCharacter;
    };

    return (
        <div className="page">
            <div className="container">
                <div className="password-reset">
                    <img src={logo} alt="Logo" />
                    <h1>HELL Export Web Store - Change your password</h1>
                    <div className="panel">
                        <form onSubmit={handleSubmit}>                           
                            <div className="form-group">
                                <label>New password</label>
                                <input type="password" value={newPassword} onChange={handleNewPasswordChange} />
                            </div>
                            <div className="form-group">
                                <label>New password again</label>
                                <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                            </div>
                            <ReCAPTCHA 
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} 
                                size="invisible" />
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <div className="button-container">
                                <button disabled={loading || disableButton} className={buttonClass}>
                                    <div className={loading ? 'loader' : ''}>{loading ? '' : 'Reset password'}</div>
                                </button>
                                {loading && <div className="progress-bar" />}
                            </div>
                            <a className="login-link" href='/login'>Login</a>
                        </form>
                    </div>
                </div>
                <ToastContainer transition={Slide} />
            </div>
        </div>
    )
}