import './products.scss';

import { ProductAPI, IProducts } from "../../apis/product-api"
import { useEffect, useState } from 'react';
import ProductItem from '../../components/product-item/product-item';
import { useCheckPermission } from '../../services/auth-service';
import { useSearchParams } from 'react-router-dom';
import { getPageItems } from '../../services/app-service';
import { IPageItem } from '../../types/page-item';
import News from '../../components/news/news.component';
import React from 'react';
import Select from 'react-select';
import { FaSortAmountDownAlt, FaSortAmountDown } from "react-icons/fa"
import { FaArrowRightLong } from "react-icons/fa6";

import usePageTitle from '../../hooks/usePageTitle';
import useGaTracker from '../../hooks/useGaTracker';
import { CategoryAPI } from '../../apis/category-api';

function Products() {
    useCheckPermission("country-manager");
    useGaTracker();
    usePageTitle("Products");

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>();
    const [products, setProducts] = useState<IProducts>();
    const [pageItems, setPageItems] = useState<IPageItem[]>();

    const [term, setTerm] = useState<string>(searchParams.get("term") ?? "");

    const [page, setPage] = useState<number>(+(searchParams.get("page") ?? 0));
    const pageChanged = (c: number) => { updateSearchParams(); setPage(c); };

    const [category, setCategory] = useState<string | undefined>(searchParams.get("category") ?? "");
    const categoryChanged = (c: string | undefined) => 
    { 
        setCategory(c); 
        setTerm('');
        setPage(0);  
    };

    const updateSearchParams = () => {
        if (category) setSearchParams({'page': page +'', 'categoryId': category + '', 'term': term });
        else  setSearchParams({'page': page +'', 'term': term });
    };

    const [filterStockOption, setFilterStockOption] = useState<{ value: string, label: string }>({ value: 'all', label: 'All' });
    const [sortOption, setSortOption] = useState<{ value: string, label: string }>({ value: 'name', label: 'Name' },);
    const [sortIsAscOption, setSortIsAscOption] = useState<boolean>(true);

    const [filterCategoryOptions, setFilterCategoryOptions] = useState<{ value: string, label: string }[]>([]);

    const filterStockOptions = [
        { value: 'in-stock', label: 'In stock' },
        { value: 'out-of-stock', label: 'Out of stock' },
        { value: 'all', label: 'All' }
    ];

    const sortOptions = [
        { value: 'name', label: 'Name' },
        { value: 'price', label: 'Price' }
    ];

    const filterInStockChanged = (selectedOption: any) => { setFilterStockOption(selectedOption); };
    const sortChanged = (selectedOption: any) => { setSortOption(selectedOption); };
    const sortDirectionChanged = () => { setSortIsAscOption(!sortIsAscOption); };

    const getAll = async () => {
        setLoading(true);          
        updateSearchParams();  

        let categories = await CategoryAPI.getAll();
        if (categories) {
            setFilterCategoryOptions(categories.map(x => ({ value: x.id, label: x.name })));
        }

        let data = await ProductAPI.getAll(page, term, category, filterStockOption.value, sortOption?.value, !sortIsAscOption);
        if (data) {
            let pageItems = getPageItems(data.count, ProductAPI.defaultPageSize, page);

            setPageItems(pageItems);
            setProducts(data);
        }

        setLoading(false);
    };

    useEffect(() => {
        getAll();
    }, [category, term, page, filterStockOption, sortOption, sortIsAscOption]);

    return (
        <>
            <News />
            <div className='products'>
                <div className='container'>
                    <div className='title-container'>
                        <div className='title'>
                            <h1>{term && term.length > 0 ? `You searched "${term}" products` : `Products`}</h1>
                            <div className='sort-direction mobile' onClick={sortDirectionChanged}>
                                {sortIsAscOption && <FaSortAmountDownAlt />}
                                {!sortIsAscOption && <FaSortAmountDown />}
                            </div>
                        </div>
                        <div className='filter-stock'>
                            <span>Stock: </span>
                            <Select
                                className='select-filter-stock first select'
                                classNamePrefix="app-select"
                                value={filterStockOption}
                                // menuIsOpen={true}
                                onChange={filterInStockChanged}
                                options={filterStockOptions}
                            />
                        </div>
                        <div className='sort'>
                            <span>Sort: </span>
                            <Select
                                className='select-sort select'
                                classNamePrefix="app-select"
                                value={sortOption}
                                onChange={sortChanged}
                                options={sortOptions}
                            />
                        </div>
                        <div className='sort-direction desktop' onClick={sortDirectionChanged}>
                            {sortIsAscOption && <FaSortAmountDownAlt />}
                            {!sortIsAscOption && <FaSortAmountDown />}
                        </div>
                    </div>
                    {term == ''&&
                    <div className="categories">
                        <div className={`category-item ${'' == category ? "active" : ""}`} 
                            onClick={() => categoryChanged(undefined)}>
                            <div className="content">All<FaArrowRightLong /></div>
                        </div>

                        {filterCategoryOptions.map((c) => (
                            <div className={`category-item ${c.value == category ? "active" : ""}`}   
                                key={c.value}                              
                                onClick={() => categoryChanged(c.value)}>
                                <div className="content">{c.label}<FaArrowRightLong /></div>
                            </div>
                        ))}
                    </div>
                    }
                    {!loading && <>
                        {products && products.items.length > 0 && <div className='products-container'>
                            {products.items.map((product) => (
                                <ProductItem
                                    key={product.id}
                                    id={product.id}
                                    sku={product.sku}
                                    name={product.name}
                                    brandName={product.brandName}
                                    image={product.image}
                                    galleryImages={product.gallery}
                                    price={product.price}
                                    priceWithDiscount={product.priceWithDiscount}
                                    preOrdered={product.preOrdered}
                                    remaining={product.remaining}
                                    inStock={product.inStock}
                                    shipped={product.shipped} />
                            ))}
                        </div>
                        }
                        {(!products || products.items.length == 0) && <>
                            <div className='empty'><p>No products found!</p></div>
                        </>}
                        {pageItems &&
                            <div className="pagination">
                                {pageItems.map((pageItem) => (
                                    <React.Fragment key={pageItem.key}>
                                        {pageItem.isDot === false && 
                                        <a className={`${pageItem.isActive ? "active" : ""}`} 
                                            onClick={() => pageChanged(pageItem.page)}>{(pageItem.page + 1)}
                                        </a>}
                                        {pageItem.isDot === true && <span>...</span>}
                                    </React.Fragment>
                                ))}
                            </div>
                        }</>
                    }
                    {loading && <div className='loading'><p>Loading...</p></div>}
                </div>
            </div></>
    );
}

export default Products;
